'use client';

import { useCallback, useEffect, useRef, useState } from 'react';

import {
  DevicePhoneMobileIcon,
  DeviceTabletIcon,
  ComputerDesktopIcon,
  UserPlusIcon,
} from '@heroicons/react/24/outline';
import classNames from 'classnames';
import Image from 'next/image';

import { ResponsiveScale } from './ResponsiveScale/ResponsiveScale';
import { PolyglotSwiper } from './PolyglotSwiper/PolyglotSwiper';

import { LocaleKey } from '@/constants/locales';
import { Dropdown, DropdownItem } from '@/components/Fields/Dropdown';
import { MaxWidthContainer } from '@/components/Layouts/MaxWidthContainer';
import { Iframe } from '@/components/widgets/Iframe/Iframe';
import { Color, DeviceFrame, DeviceType, colors } from '@/components/widgets/DeviceFrame/DeviceFrame';
import { useIsIntersecting } from '@/hooks/useIsIntersecting';
import { Example } from '@/data/examples/ExampleTypes';
import { Translation } from '@/components/Translation';

type UserDevice = {
  device: DeviceType;
  deviceColor?: Color;
  active: boolean;
  localeKey?: LocaleKey;
  src?: string;
};

type ExampleInstance = {
  example: Example;
  timestamp?: number;
};

type PolyglotExampleProps = {
  className?: string;
  examples: Example[];
  maxUsers?: number;
  showOverflow?: boolean;
  demoMode?: boolean;
};

const devices: DropdownItem[] = [
  { label: 'Phone', value: 'mobile', icon: (props) => <DevicePhoneMobileIcon {...props} /> },
  { label: 'Tablet', value: 'tablet', icon: (props) => <DeviceTabletIcon {...props} /> },
  { label: 'Laptop', value: 'laptop', icon: (props) => <ComputerDesktopIcon {...props} /> },
];

export const PolyglotExample = (props: PolyglotExampleProps) => {
  const { className, examples, maxUsers = Infinity, showOverflow, demoMode } = props;
  const [exampleInstance, setExampleInstance] = useState<ExampleInstance>({
    example: examples[0],
    timestamp: undefined,
  });
  const [yourDevice, setYourDevice] = useState<DeviceType>('mobile');
  const [users, setUsers] = useState<UserDevice[]>(
    [
      { device: 'mobile', active: true, deviceColor: 'pink', localeKey: 'fr' },
      { device: 'mobile', active: true, deviceColor: 'blue' },
      { device: 'mobile', active: true, deviceColor: 'indigo', localeKey: 'ro' },
    ].splice(0, maxUsers) as UserDevice[],
  );
  const [usableDevice, setUsableDevice] = useState<UserDevice>();
  const containerRef = useRef<HTMLDivElement>(null);
  const yourDeviceRef = useRef<HTMLDivElement>(null);
  const userDeviceRef = useRef<HTMLDivElement>(null);
  const isIntersecting = useIsIntersecting(containerRef);
  const [hasIntersected, setHasIntersected] = useState(false);
  const activeUser = users.find((user) => user.active) as UserDevice;
  const speakers = examples.map((e) => ({ label: e.speaker, subLabel: e.name, value: e.id, image: e.img }));

  const postLocaleKeys = useCallback(() => {
    const dashboardIframe = document.querySelector<HTMLIFrameElement>('#dashboard-iframe');
    if (!dashboardIframe) return;
    const localeKeys = users.map((user) => user.localeKey).filter((localeKey) => localeKey) as LocaleKey[];
    dashboardIframe.contentWindow?.postMessage({ type: 'localeKeys', localeKeys }, '*');
  }, [users]);

  // use effect is intersecting set timestamp

  useEffect(() => {
    if (isIntersecting === true) setHasIntersected(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIntersecting]);

  useEffect(() => {
    setExampleInstance((exampleInstance) => {
      setTimeout(() => setUsers((users) => [...users]));
      return {
        ...exampleInstance,
        timestamp: !hasIntersected ? undefined : exampleInstance.timestamp || new Date().getTime(),
      };
    });
  }, [hasIntersected]);

  useEffect(() => {
    users.forEach((user) => {
      if (!exampleInstance.timestamp) return;
      user.src = `/polyglot/demo/${exampleInstance.example.id}?locale=${user.localeKey || ''}&timestamp=${exampleInstance.timestamp + 100}`;
    });
  }, [exampleInstance.example.id, exampleInstance.timestamp, users]);

  // Listen for messages from the child frame
  useEffect(() => {
    window.addEventListener(
      'message',
      function (event) {
        if (event.origin !== window.location.origin) return;
        if (event.data.type !== 'language-change') return;
        const user = users.find(
          (_, index) =>
            document.querySelector<HTMLIFrameElement>(`#user-device-${index}`)?.contentWindow === event.source,
        );
        if (user) {
          user.localeKey = event.data.language;
          postLocaleKeys();
        }
      },
      false,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      ref={containerRef}
      className={classNames(className, {
        'overflow-clip lg:overflow-x-scroll': showOverflow !== true,
        'py-[500px]': demoMode,
      })}
    >
      <MaxWidthContainer>
        <div
          className={classNames('flex flex-col justify-center gap-8 sm:gap-20 lg:flex-row', {
            '!justify-between !gap-10': maxUsers === 1,
          })}
        >
          <div className="flex flex-col items-center justify-center gap-4 md:gap-10">
            <div className="flex flex-col gap-2 text-primary-300">
              <div className="bg-primary-300 bg-clip-text text-center text-3xl text-transparent">
                👇 <Translation name="polyglot.your-device">Your Device</Translation>
              </div>
              <div
                className={classNames('flex justify-center whitespace-nowrap text-primary-300', { hidden: demoMode })}
              >
                <Dropdown
                  className="mx-auto"
                  items={devices}
                  onSelect={(item) => setYourDevice(item.value as DeviceType)}
                  selectedItem={devices.find(({ value }) => value === yourDevice)}
                >
                  <DeviceIcon device={yourDevice} />
                  {devices.find(({ value }) => value === yourDevice)?.label}
                </Dropdown>
                <Dropdown
                  items={speakers}
                  selectedItem={speakers.find(({ value }) => value === exampleInstance.example.id)}
                  onSelect={(item) => {
                    const example = examples.find((e) => e.id === item.value);
                    setExampleInstance(example ? { example, timestamp: Date.now() } : exampleInstance);
                  }}
                >
                  <Image
                    className="!rounded-full"
                    src={exampleInstance.example.img}
                    width={22}
                    height={22}
                    alt={exampleInstance.example.speaker}
                  />
                  {exampleInstance.example.name}
                </Dropdown>
              </div>
            </div>
            <ResponsiveScale
              breakpoints={{
                '(min-width:1300px)': {
                  scale: 0.75,
                  fixMarginsY: true,
                  fixMarginsX: true,
                  defaultMargins: { y: -104, x: 49.375 },
                },
                '(min-width:1px) and (max-width:1299px)': {
                  scale: 0.5,
                  fixMarginsY: true,
                  fixMarginsX: true,
                  defaultMargins: { y: -208, x: -98.75 },
                },
              }}
            >
              <div ref={yourDeviceRef}>
                <DeviceFrame device={yourDevice}>
                  {hasIntersected && exampleInstance.timestamp && (
                    <Iframe
                      id="dashboard-iframe"
                      className="absolute inset-0 h-full w-full"
                      src={`/polyglot/demo/dashboard?example=${exampleInstance.example.id}&timestamp=${exampleInstance.timestamp}`}
                    />
                  )}
                </DeviceFrame>
              </div>
            </ResponsiveScale>
          </div>
          <div className="flex flex-col gap-4 md:gap-10">
            <div className="z-50 flex flex-col gap-2 text-primary-300 lg:translate-x-5">
              <div className="bg-primary-300 bg-clip-text text-center text-3xl text-transparent">
                👇 <Translation name="polyglot.your-users">Your Users</Translation>
              </div>
              <div
                className={classNames('flex justify-center whitespace-nowrap lg:translate-x-2', {
                  hidden: demoMode,
                })}
              >
                <Dropdown
                  items={devices}
                  selectedItem={devices.find(({ value }) => value === activeUser.device)}
                  onSelect={(item) => {
                    activeUser.device = item.value as DeviceType;
                    setUsers((users) => [...users]);
                  }}
                >
                  <DeviceIcon device={activeUser.device} />
                  {devices.find(({ value }) => value === activeUser.device)?.label}
                </Dropdown>
                {users.length < maxUsers && (
                  <Dropdown
                    className="lt-sm:hidden"
                    items={devices}
                    onSelect={(item) => {
                      activeUser.active = false;
                      const newUser: UserDevice = {
                        device: item.value as DeviceType,
                        active: true,
                        deviceColor: colors[(users.length - 1) % colors.length],
                      };
                      setUsers((users) => [...users, newUser]);
                    }}
                  >
                    <UserPlusIcon className="h-5 w-5" />
                    Add
                  </Dropdown>
                )}
              </div>
            </div>
            <div
              className={classNames('m-auto lt-sm:relative', {
                'lt-sm:left-[50%] lt-sm:translate-x-[-50%]': maxUsers > 1,
              })}
            >
              <ResponsiveScale
                breakpoints={{
                  '(min-width:1300px)': {
                    scale: 0.75,
                    fixMarginsY: true,
                    fixMarginsX: true,
                    defaultMargins: { y: -104, x: -125 },
                  },
                  '(min-width:1px) and (max-width:1299px)': {
                    scale: 0.5,
                    fixMarginsY: true,
                    fixMarginsX: true,
                    defaultMargins: { y: -208, x: -250 },
                  },
                }}
              >
                <PolyglotSwiper
                  defaultOpenIndex={1}
                  onSlideIndexChange={(index) => {
                    users.forEach((user, i) => (user.active = i === index));
                    setUsers((users) => [...users]);
                  }}
                  className="lt-sm:!-ml-8"
                  enabled={maxUsers > 1}
                >
                  {users.map((user, index) => (
                    <DeviceFrame
                      key={index}
                      device={user.device}
                      color={user.deviceColor}
                      glow={usableDevice === user}
                      shadow={maxUsers > 1}
                      className={classNames('transition-all', {
                        'opacity-20': usableDevice && user !== usableDevice,
                      })}
                    >
                      <div ref={user === activeUser ? userDeviceRef : undefined}>
                        {usableDevice && usableDevice !== user && (
                          <div
                            onClick={() => setUsableDevice(undefined)}
                            className="absolute bottom-0 top-0 z-[101] h-full w-full bg-transparent lt-sm:backdrop-blur-xl"
                          ></div>
                        )}
                        {user !== usableDevice && (
                          <div
                            className="absolute bottom-0 top-0 z-20 w-full bg-transparent"
                            onClick={() => user === activeUser && setUsableDevice(user)}
                            onMouseEnter={() => user !== activeUser && setUsableDevice(undefined)}
                          ></div>
                        )}
                        {hasIntersected && user.src && (
                          <Iframe
                            id={`user-device-${index}`}
                            src={user.src}
                            onLeave={() => setUsableDevice(undefined)}
                          />
                        )}
                      </div>
                    </DeviceFrame>
                  ))}
                </PolyglotSwiper>
              </ResponsiveScale>
            </div>
          </div>
        </div>
      </MaxWidthContainer>
    </div>
  );
};

const DeviceIcon = ({ device }: { device: DeviceType }) => {
  const Icon = devices.find(({ value }) => value === device)?.icon;
  return Icon && <Icon className="h-5 w-5" />;
};
